<template>
	<SearchResultListItem
		:ref="CAROUSEL_ITEM"
		:result="result"
		:is-mobile="isMobile"
		:list-type="result.listType"
		:img-alt="imgAlt"
	>
		<template #row2>
			<span class="mr-2 capitalize truncate">
				{{ result.blogCategory }}
			</span>
		</template>

		<template #row3>
			<div class="text-left">
				<div class="flex items-center">
					<img
						:src="result.icon"
						alt="Category Icon"
						class="mr-2"
						width="17"
						height="17"
					>
					<span class="mr-2 capitalize">
						{{ result.category }}
					</span>
					<span class="mr-2 text-gray-300">
						•
					</span>
					<span class="truncate">
						by <span class="font-bold">
							WW
						</span>
					</span>
				</div>
			</div>
		</template>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		compact: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			CAROUSEL_ITEM
		}
	},
	computed: {
		daysSincePosted() {
			const today = new Date()
			const differenceInTime = this.result.date - (today.getTime() / 1000)
			const differenceInDays = differenceInTime / (3600 * 24)
			const formattedDays = Math.abs(Math.round(differenceInDays))
			if (formattedDays > 0) {
				return `${formattedDays} days ago`
			} else {
				return 'Today'
			}
		},
		imgAlt() {
			return this.result?.title || ' '
		}
	}
}
</script>
