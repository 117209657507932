<template>
	<router-link
		:to="{ path: result.link }"
		class="flex flex-none group"
		:class="[
			cardHeightClass,
			{ 'bg-white': !isMobile }
		]"
	>
		<div
			:id="result.title"
			class="w-full text-black observe"
			:class="isMobile ? 'py-2.5' : 'mx-0 my-0'"
		>
			<div
				class="min-w-full"
				:class="isMobile ? 'flex content-between' : 'grid grid-cols-1 rounded-xl'"
			>
				<div
					v-if="!isLocationCard"
					:class="[isMobile ? 'thumbnail-size' : 'w-full h-36 block border-b border-gray-300', 'relative']"
				>
					<WwImage
						:src="result.image"
						:classes="isMobile ? 'rounded-lg border border-gray-300 w-full h-full' : 'rounded-t-xl border-none w-full'"
						object-cover
						:alt="imgAlt"
						:fallback-image="DEFAULT_LEAF_IMAGE"
						:width="isMobile ? 100 : 216"
						:height="isMobile ? 100 : 144"
						:image-path="result.imagePath"
					/>
				</div>
				<div
					ref="card-body"
					class="grid items-stretch grid-cols-1 p-2"
					:class="cardInnerPaddingClass"
				>
					<slot name="row1">
						<div :class="{ 'h-16': !isMobile }">
							<div
								class="font-bold leading-snug text-left capitalize line-clamp-2"
								aria-hidden="true"
							>
								{{ result.title }}
							</div>
							<div
								v-if="!isMobile && result.recreational"
								class="block p-0 m-0 text-xs font-normal text-left text-gray-500"
							>
								Med & Rec
							</div>
						</div>
					</slot>

					<div
						v-if="!isLocationCard && !hideSecondRow"
						class="relative flex items-center w-full pb-1 text-left"
					>
						<slot
							name="row2"
							:has-strain-type="hasStrainType"
							:has-rating="hasRating"
							:star-icon="starIcon"
							:formatted-average="formattedAverage"
						>
							<div
								v-if="showStarRating"
								class="flex items-center mr-2.5"
							>
								<img
									:src="starIcon"
									width="16"
									height="16"
									class="mr-1"
									alt="Star Icon"
								>
								<span class="font-bold">
									{{ formattedAverage }}
								</span>
								<span
									v-if="!isMobile"
									class="ml-1 mr-4 text-sm"
								>
									({{ reviewCount }})
								</span>
							</div>
							<img
								v-if="isMobile"
								:src="result.icon"
								alt="Category Icon"
								class="mr-2"
								:width="isMobile ? 15 : 16"
								:height="isMobile ? 15 : 16"
							>
							<span
								v-if="isMobile"
								:class="[
									{'text-sm': isMobile},
									'mr-2 capitalize'
								]"
							>
								{{ result.category }}
							</span>
							<div
								v-if="showOrderChip"
								class="w-24 ml-auto text-sm font-bold text-center text-green-500 uppercase border-2 border-green-500 rounded-md group-hover:text-white group-hover:bg-green-500"
							>
								Order
							</div>
						</slot>
					</div>
					<slot name="row3" />
				</div>
			</div>
		</div>
	</router-link>
</template>

<script async>
import { DEFAULT_LEAF_IMAGE, DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { CHAIN_LIST, STRAINS_LIST_COMPACT, STRAINS_LIST_FULL } from '@/constants/search/listTypes.js'
import { DOCTOR } from '@/constants/search/resultTypes.js'
import requireIcon from '@/utils/requireIcon.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		isLocationCard: {
			type: Boolean,
			default: false
		},
		imgAlt: {
			type: String,
			default: 'image'
		},
		hideSecondRow: {
			type: Boolean,
			default: false
		},
		isAutocompleter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			useFallbackImage: false,
			DOCTOR,
			DEFAULT_LEAF_IMAGE,
			DEFAULT_WW_GRAY_LOGO,
			CHAIN_LIST
		}
	},
	computed: {
		isDoctorCard() {
			return this.result?.category?.toLowerCase() === DOCTOR
		},
		isStrainCard() {
			return this.listType === STRAINS_LIST_FULL || this.listType === STRAINS_LIST_COMPACT
		},
		isChainCard() {
			return this.listType === CHAIN_LIST
		},
		imageSrcLazy() {
			if (this.isStrainCard) { return DEFAULT_LEAF_IMAGE }
			return DEFAULT_WW_GRAY_LOGO
		},
		image() {
			return this.result?.image || false
		},
		cardHeightClass() {
			return [
				this.isMobile
					? 'w-full'
					: 'border border-gray-300 shadow-md hover:shadow-lg rounded-xl',
				{ 'location-card-height': this.isLocationCard },
				{ 'chain-card-dimensions': this.isChainCard },
				{ 'search-card-dimensions': !this.isMobile && !this.isChainCard && !this.isLocationCard }
			]
		},
		cardInnerPaddingClass() {
			return [
				{ 'py-2.5 px-3': this.isMobile && !this.isLocationCard },
				{ 'location-card-height px-0 py-0': this.isLocationCard }
			]
		},
		hasRating() {
			return !!Number(this.result.rating)
		},
		hasReviewCount() {
			return !!Number(this.result.reviewCount)
		},
		hasStrainType() {
			return !!this.result?.strainType && !!this.result?.strainType.length
		},
		reviewAverage() {
			const tempAvg = (Math.round(this.result?.rating * 10) / 10).toFixed(1)
			return isNaN(tempAvg) ? 0 : tempAvg
		},
		reviewCount() {
			return !!Number(this.result.reviewCount) && this.result?.reviewCount || 0
		},
		showRating() {
			return !!this.result.rating
		},
		formattedAverage() {
			return this.reviewAverage % 1 === 0 ? parseInt(this.reviewAverage) : this.reviewAverage
		},
		starIcon() {
			if (!this.hasRating) {
				return requireIcon('star-full-gray.svg')
			} else {
				return requireIcon('star-full-yellow.svg')
			}
		},
		hasOrdering() {
			return this.result.hasOrdering
		},
		showOrderChip() {
			return !this.isMobile && !this.isDoctorCard && this.hasOrdering
		},
		showStarRating() {
			return !this.isAutocompleter
		}
	}
}
</script>

<style lang="scss" scoped>
	.search-card-dimensions {
		min-height: 17.5rem;
		max-height: 17.5rem;
		width: 12.7rem;
		max-width: 12.7rem;
	}
	.thumbnail-size {
		width: 5rem;
		height: 5rem;
		min-width: 5rem;
	}
	.location-card-height {
		height: auto;
		min-height: none;
		max-height: none;
	}
	.chain-card-dimensions {
		@apply w-[200px] h-74;
	}
</style>
